<template>
  <div id="subpage-content">
    <ProductDrawer />
    <div id="rcol">
      <v-card id="rcol-incard">
        <h1 id="naming">{{ $t("message.NavbarDelivery") }}</h1>
        <div id="int-about-text">
          <img
            height="150"
            width="230"
            :src="getImageUrlbyName('AdS4')"
          />
          <a id="pad">{{ $t("message.DeliverText1") }}</a>
          <a id="pad">{{ $t("message.DeliverText2") }}</a>
          <a id="pad">{{ $t("message.DeliverText3") }}</a>
          <a id="pad">{{ $t("message.DeliverText4") }}</a>
          <a id="pad">{{ $t("message.DeliverText5") }}</a>
          <!-- <a id="pad">{{ $t("message.DeliverStep1") }}</a>
          <a id="pad">{{ $t("message.DeliverStep2") }}</a>
          <a id="pad">{{ $t("message.DeliverStep3") }}</a>
          <img
            height="350"
            width="600"
            src="../../../assets/images/step3.png"
          />
          <a id="pad">{{ $t("message.DeliverStep4") }}</a>
          <img
            height="350"
            width="600"
            src="../../../assets/images/step4.png"
          /> -->
          <a id="pad">{{ $t("message.DeliverText6") }}</a>
          <a>{{ $t("message.DeliverText7") }}</a>
          <a id="pad">{{ $t("message.DeliverText8") }}</a>
          <a>{{ $t("message.DeliverText9") }}</a>
        </div>
        <v-dialog v-model="dialog" width="500" id="fb-bg">
          <template v-slot:activator="{ on }">
            <v-card id="buy-card">
              <v-btn v-on="on" small id="buy-btn"
                >{{ $t("message.BuyService") }}
              </v-btn>
              <div id="history-card-text">
                {{ $t("message.MakeRequest") }}
              </div>
            </v-card>
          </template>
          <v-card min-height="500">
            <div id="feedback-marg">
              <Feedback />
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
import Feedback from '../../../components/Feedback';
import ProductDrawer from "../../../views/Navigations/Products/ProductDrawer";
export default {
  name: "Integration",
  components: {
    ProductDrawer,
    Feedback,
  },
};
</script>